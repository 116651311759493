/**
 * @license
 * Copyright 2016 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-viewer {
  outline: 0px;
}

.neuroglancer-viewer-context-menu > label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.neuroglancer-viewer-context-menu > label > input::before {
  flex: 1;
  content: " ";
}

.neuroglancer-viewer-context-menu > label > input {
  margin-left: 5px;
  width: 11ch;
}

.neuroglancer-viewer-context-menu-limit-widget > input {
  width: 11ch;
}

.neuroglancer-viewer-top-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #222;
  margin-bottom: 3px;
  padding-right: 2px;
}

.neuroglancer-viewer-side-panel {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 80%;
  color: #fff;
}
