/**
 * @license
 * Copyright 2020 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-selection-details {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.neuroglancer-selection-details-body {
  height: 0px;
  flex: 1;
  overflow-y: auto;
  flex-basis: 0px;
  min-height: 0px;
}

.neuroglancer-selection-details-titlebar {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: sans-serif;
  font-size: 10pt;
  background-color: #03c;
  padding: 2px;
  color: white;
}

.neuroglancer-selection-details-title {
  flex: 1;
}

.neuroglancer-selection-details-position {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: left;
  font-family: monospace;
  font-size: medium;
}

.neuroglancer-selection-details-position-dimension + .neuroglancer-selection-details-position-dimension {
  margin-left: 8px;
}

.neuroglancer-selection-details-position-dimension-name {
  color: #ff6;
}

.neuroglancer-selection-details-position-dimension-coordinate {
  margin-left: 5px;
  user-select: text;
}

.neuroglancer-selection-details-layer {
  margin-top: 4px;
  margin-bottom: 4px;
  border: 1px solid #222;
}

.neuroglancer-selection-details-layer-title {
  cursor: pointer;
  background-color: #222;
  font-family: sans-serif;
  font-size: 10pt;
}

.neuroglancer-selection-details-layer-title:hover {
  background-color: #333;
}

.neuroglancer-selection-details-layer-body {
  padding: 2px;
  display: flex;
  flex-direction: column;
}
